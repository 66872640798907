export default {
  whatWeDo: {
    hero: {
      title: 'Qué Hacemos',
    },
    cards: {
      rpa: {
        title: 'RPA',
        text: 'Automatización de tareas repetitivas y burocráticas',
        linkText: 'más información',
      },
      people: {
        title: 'Gente',
        text: 'Reclutamiento, Gestión y Desarrollo',
        linkText: 'más información',
      },
      process: {
        title: 'Procesos',
        text: 'Implantación, Administración y Optimización',
        linkText: 'más información',
      },
      tech: {
        title: 'Tecnologías',
        text: 'Identificación, Ejecución y Monitoreo',
        linkText: 'más información',
      },
      hunting: {
        title: 'Tech. <br> Hunting',
        text: 'Contratación Asertiva y Ágil ',
        linkText: 'más información',
      },
      sap: {
        title: 'Inteligencia SAP',
        text: 'Consultoría, Implantación y Apoyo AMS',
        linkText: 'más información',
      },
    },
    conectPeople: {
      title: 'Conectamos Personas, Procesos y Tecnologías',
      text: 'Somos una empresa especializada en inteligencia tecnológica que desarrolla la estrategia ideal para entregar las mejores soluciones personalizadas para cada desafío de nuestros clientes.',
      footer: '¡Para nosotros cada cliente es único!',
    },
    innovationWhell: {
      title: 'Innovación y Transformación Digital',
      subtitle: 'Qué Hacemos',
      eye: {
        title: 'Evaluación de<br> Madurez Digital ',
        description:
          '<span class="font-bold">Suministramos una evaluación detalladan</span> de madurez y eficiencia en la utilización de tecnologías para guiar a las organizaciones en sus iniciativas de Innovación y Transformación Digital.',
      },
      system: {
        title: 'Desarrollo de Aplicaciones Digitales',
        description:
          '<span class="font-bold">Desarrollamos soluciones digitales a medida,</span> incluyendo aplicaciones personalizadas y la integración de sistemas heredados con nuevas soluciones digitales.',
      },
      horse: {
        title: 'Transformación Continua',
        description:
          '<span class="font-bold">Promovemos la evolución tecnológica</span> a través de procesos eficaces de cambio, que incluyen entrenamiento y apoyo continuo.',
      },
      document: {
        title: 'Consultoría en Estrategia Digital',
        description:
          '<span class="font-bold">Orientamos la definición de metas</span> acordes con la era digital y la planificación de estrategias innovadoras para alcanzar la Transformación Digital.',
      },
      glass: {
        title: 'Innovación e Investigación de Mercado',
        description:
          '<span class="font-bold">Realizamos investigaciones para identificar</span> tendencias de mercado e innovaciones, aplicándolas para desarrollar u optimizar productos y servicios.',
      },
      mental: {
        title: 'Automatización de Procesos, Integridad e Inteligencia',
        description:
          '<span class="font-bold">Somos especialistas en Datos e Inteligencia aplicada</span> y en automatización de procesos con RPA e IA orientados hacia la optimización y eficiencia operacional.',
      },
    },
    tabs: {
      people: {
        title: 'Gente',
        subtitle:
          'Somos una empresa de personas para personas. Creemos que las relaciones son importantes en el día a día y el desarrollo de las empresas.',
        cards: [
          {
            title: 'Reclutamiento y Tech. Hunting:',
            list: ['• Identificación y atracción de talentos especializados en tecnología e Innovación Digital.'],
            textButton: 'saber más',
            link: '/hunting',
          },
          {
            title: 'Desarrollo y Entrenamiento:',
            list: [
              '• Programas de capacitación en tecnologías emergentes;',
              '• Metodologías ágiles;',
              '• DevOps;',
              '• Diseño UX/UI;',
              '• Innovación abierta.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Desarrollo de Liderazgo y Cultura de Innovación:',
            list: [
              '• Coaching y workshops para liderazgo digital;',
              '• Gestión del cambio;',
              '• Formación de maestros digitales;',
              '• Adaptación cultural para Transformación Digital.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Formación de Squads y Designación de Talentos:',
            list: [
              '• Montaje de equipos ágiles multidisciplinares para proyectos específicos;',
              '• Designación de profesionales especializados en Transformación Digital e Innovación.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
        ],
      },
      process: {
        title: 'Procesos',
        subtitle:
          'Desarrollamos estrategias personalizadas para cada cliente, según las necesidades específicas de cada empresa. Cada etapa es importante, desde la consultoría hasta el entrenamiento y el apoyo.',
        cards: [
          {
            title: 'Automatización y Optimización de Procesos:',
            list: [
              '• Consultoría de RPA;',
              '• Lean Management;',
              '• Six Sigma;',
              '• Integración de Inteligencia artificial para procesos empresariales.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Frameworks de Desarrollo Ágil:',
            list: [
              '• Implementación y entrenamiento en frameworks ágiles en escala (SAFe, Less, Nexus);',
              '• DevSecOps y gestión ágil de portfolio.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Estrategias de Mercado y Go-to-Market:',
            list: [
              '• Asesoría para definición e implementación de estrategias de mercado;',
              '• Análisis de datos;',
              '• Inteligencia competitiva.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Gestión de Innovación y Alianzas Estratégicas:',
            list: [
              '• Creación de procesos de ideación, brainstorming y laboratorios de innovación;',
              '• Fomento de colaboraciones estratégicas.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Centro de Excelencia en Calidad (CEQ)',
            list: [
              '• Implementación de estándares de excelencia en todas las etapas del proyecto;',
              '• Establecimiento de criterios rigurosos de aceptación y automatización de pruebas de eficiencia;',
              '• Capacitación de los equipos técnicos, monitoreo por KPI y conformidad con las normas.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
        ],
      },
      tech: {
        title: 'Tecnología',
        subtitle:
          'Impulsamos negocios con nuestros servicios y productos y estamos siempre buscando las mejores soluciones para nuestros clientes. ¡Le ofrecemos lo más innovador que existe!',
        cards: [
          {
            image: '/images/services-products/cards/card-sap.webp',
            title: 'Inteligencia SAP',
            list: [
              '• Consultoría e implantación SAP;',
              '• SAP ECC;',
              '• SAP S/4HANA;',
              '• Suporte AMS;',
              '• SAP CONCUR;',
              '• PRICEFX;',
              '• OMNIPOS (Omnicanal);',
              '• ANFE.',
            ],
            link: '/o-que-fazemos/inteligencia-sap',
            textButton: 'saber más',
          },
          {
            title: 'Aplicaciones Multiplataforma a medida:',
            list: [
              '• Desarrollo de Sistemas;',
              '• Ingeniería de software incluyendo sistemas de nube;',
              '• Mobile y Web;',
              '• Foco en UX/UI y seguridad integrada.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Desarrollo, Implantación y Customización:',
            list: [
              '• De plataformas de mercado (ERPs SAP y Totvs);',
              '• De chatbots inteligentes;',
              '• En herramientas Low code/No Code;',
              '• Pega, Innoveo, Salesforce y Totvs.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Big Data, Analytics e Inteligencia Artificial:',
            list: [
              '• Soluciones para análisis de grandes volúmenes de datos;',
              '• Minería de datos;',
              '• Implementación de Sistemas de Recomendación con IA.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Apoyo a Sistemas:',
            list: [
              '• Continuidad de TI;',
              '• Monitoreo 24x7;',
              '• Mantenimiento Correctivo, Adaptativo y Evolutivo;',
              '• Apoyo Técnico;',
              '• Gestión de Configuración;',
              '• Evaluación de Desempeño;',
              '• Evolución Sistémica.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Cloud Computing:',
            list: [
              '• Servicios de modernización e integración heredada;',
              '• Arquitectura de sistemas;',
              '• Migración para nube;',
              '• Administración de infraestructura.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Monitoreo, Telemetría y DevOps:',
            list: [
              '• Implementación de soluciones de monitoreo en tiempo real;',
              '• Telemetría avanzada;',
              '• Prácticas de CI/CD para optimización continua.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Plataforma RPA:',
            list: [
              '• Automatización de procesos burocráticos y aumento de la productividad del equipo; Integración con múltiples herramientas;',
              '• Reducción de costos;',
              '• Seguridad de la información y LGPD y reducción de fallas y riesgos;',
              '• Adquisición como servicio, pago mensual para usar, y adaptable a cualquier tamaño de empresa.',
            ],
            link: '/o-que-fazemos/rpa',
            textButton: 'saber más',
          },
          {
            title: 'Cyber Security (software):',
            list: [
              '• Pentest;',
              '• Análisis y corrección de vulnerabilidad;',
              '• BlueRed Team;',
              '• Gestión de Accesos.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
          {
            title: 'Infraestructura de TI:',
            list: [
              '• Gestión de Nube Pública, Privada y Data Center Convencional;',
              '• Capacity Plan;',
              '• Gestión de Ambiente de TI (servidores, firewall, switches e inalámbricos);',
              '• Migración de Ambientes on premise para Nube;',
              '• Monitoreo NOC y SOC;',
              '• Apoyo y Help Desk.',
            ],
            textButton: 'Hable con nosotros',
            link: '/contato',
          },
        ],
      },
    },
    ourDifferences: {
      title: 'Nuestros Factores Diferenciales',
      items: [
        {
          title: 'Abordaje Completo <br class="desktop">y Personalizado',
          text: 'Soluciones adaptadas a las necesidades únicas de cada cliente, considerando sus objetivos, cultura y desafíos específicos.',
          icon: '/images/services-products/icons/1.webp',
        },
        {
          title: 'Especialización <br class="desktop">Sectorial',
          text: 'Somos expertos en diversos sectores, ofreciendo soluciones que reflejan la realidad de cada industria.',
          icon: '/images/services-products/icons/2.webp',
        },
        {
          title: 'Ecosistema de Aliados Estratégicos',
          text: 'Colaboraciones con líderes tecnológicos, startups y académicos para potenciar la innovación. ',
          icon: '/images/services-products/icons/3.webp',
        },
        {
          title: 'Evaluación de Riesgos<br class="desktop"> y Seguridad',
          text: 'Foco en la seguridad cibernética como pilar del proceso de Transformación Digital.',
          icon: '/images/services-products/icons/4.webp',
        },
        {
          title: 'Workshops y <br class="desktop">Eventos Educativos',
          text: 'Oferta de eventos para compartir conocimientos y percepciones relevantes.',
          icon: '/images/services-products/icons/5.webp',
        },
        {
          title: 'El Cliente en el Centro',
          text: 'Garantizamos que las entregas satisfagan y superen las expectativas del cliente aún frente a desafíos complejos.',
          icon: '/images/services-products/icons/6.webp',
        },
      ],
    },
    contact: {
      title: '¡Hable con nosotros!',
      description:
        '¿Desea conversar con un especialista? Entonces hable con nosotros. Tenemos un equipo cualificado para atender sus necesidades. ',
      buttonText: 'Cuente con nosotros',
    },
    midia: {
      title: 'SIS en los Medios',
      desc1: 'Nuestros especialistas y servicios se destacan en varios canales de comunicación del país.',
      desc2: '¡Vea las noticias completas y conózcanos mejor!',
      buttonText: 'Vea todo',
    },
  },
}
