export default {
  footer: {
    text1:
      'Desde 2003 integramos tecnología, procesos y personas para atender las demandas más desafiadoras del mercado.',

    text2: 'Privacidad',

    text3: 'Condiciones de uso',

    text4: 'Código de conduta',

    text5: 'Servicios',

    text6: 'Estrategia',

    text7: 'Transformación digital',

    text8: 'Sustentación de ambientes',

    text9: 'Desarrollo',

    text10: 'Soluciones Low Code',

    text11: 'Contacto',

    text12: 'contato@sis-it.com',

    text13: '+55 11 3711 6530',

    text14: 'Ombudsman',

    text15: 'Denuncias',

    text16: '© 2021 SIS. Derechos Reservados.',

    text17: 'LGPD',

    text18: ['Síganos en nuestras', 'redes sociales'],

    text19: 'Política de Cookies',
    text20: 'Manifiesto ESG',
  },
}
