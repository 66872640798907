export default {
  navbar: {
    sisResume:
      'Ofrecemos servicios que transforman, estimulan, facilitan <br>y optimizan. <span class="font-bold">Conozca todas nuestras soluciones:</span>',
    cards: {
      first: {
        title: 'Transformación digital',
        buttonText: 'Ingresar',
      },
      second: {
        title: 'Servicios',
        buttonText: 'Ingresar',
      },
    },
    weAreSis: {
      title: 'Quiénes somos',
      itens: {
        about: 'Quiénes somos',
        clients: 'Clientes y Aliados',
        successCases: 'Casos de Éxito',
      },
    },
    solutions: {
      title: 'Soluciones',
      itens: {
        hunting: 'Tech. Hunting',
        consulting: 'Consultoría Estratégica',
        development: 'Desarrollo Web y Mobile',
        sustentation: 'Sustentación de Ambientes',
        bi: 'Datos e Inteligencia Aplicada',
        cloud: 'Servicios de Cloud Computing',
        erp: 'Soluciones ERP',
        lowCode: 'Soluciones Low Code',
        quality: 'Quality Assurance',
        crmSolutions: 'Soluciones en CRM',
      },
    },
    innovation: {
      title: 'Innovación',
      itens: {
        design: 'Design Thinking',
        transformation: 'Transformación Digital',
        ux: 'UX y UI',
        devOps: 'DevOps',
      },
    },
    career: {
      title: 'Carrera',
      itens: {
        talk: 'Employer Branding',
        sisAcademy: 'SIS Academy',
        apprenticeship: 'Programa de Formación SIS',
      },
    },
    products: {
      title: 'Lo que hacemos',
    },
    blog: {
      title: 'Blog',
    },
    contact: {
      title: 'Contacto',
    },
    beASis: {
      title: 'PÁGINA DE VACANTES',
    },
    language: {
      title: 'ESP',
    },
    midia: {
      title: 'SIS en los medios',
    },
    buttonContact: 'Contacto',
  },
}
