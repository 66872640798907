export default {
  navbar: {
    sisResume:
      'We provide services that transform, boost, facilitate, <br>and optimize. <span class="font-bold">Check out all of our solutions:</span>',
    cards: {
      first: {
        title: 'Digital Transformation',
        buttonText: 'Sing in',
      },
      second: {
        title: 'Services',
        buttonText: 'Sing in',
      },
    },
    weAreSis: {
      title: 'Who we are',
      itens: {
        about: 'Who we are',
        clients: 'Clients and Partners',
        successCases: 'Success Cases',
      },
    },
    solutions: {
      title: 'Solutions',
      itens: {
        hunting: 'Tech. Hunting',
        consulting: 'Strategic Consulting',
        development: 'Web, Mobile, and Mainframe Development',
        sustentation: 'Environment Support',
        bi: 'Data and Applied Intelligence',
        cloud: 'Cloud Computing Services',
        erp: 'ERP Solutions',
        lowCode: 'Low Code Solutions',
        quality: 'Quality Assurance',
        crmSolutions: 'CRM Solutions',
      },
    },
    innovation: {
      title: 'Innovation',
      itens: {
        design: 'Design Thinking',
        transformation: 'Digital Transformation',
        ux: 'UX and UI',
        devOps: 'DevOps',
      },
    },
    career: {
      title: 'Career',
      itens: {
        talk: 'Employer Branding',
        sisAcademy: 'SIS Academy',
        apprenticeship: 'Learning at SIS',
      },
    },
    products: {
      title: 'What we do',
    },
    blog: {
      title: 'Blog',
    },
    contact: {
      title: 'Contact us',
    },
    beASis: {
      title: 'Job Opportunity Page',
    },
    language: {
      title: 'ENG',
    },
    midia: {
      title: 'SIS in the media',
    },
    buttonContact:'Contact us'
  },
}
