import hero from './hero'
import howWeAre from './howWeAct'
import contentImageSideAndBottom from './contentImageSideAndBottom'
import howWeDo from './howWeDo'
import clients from './clients'
import manifest from './manifest'

export default {
  ...hero,
  ...howWeAre,
  ...contentImageSideAndBottom,
  ...howWeDo,
  ...clients,
  ...manifest,
  metatag: {
    title: 'About Us - SIS Innov & Tech',
    description:
      "We've been operating in the market for 20 years, as a strategic innovation and digital transformation consulting company. We are specialized in boosting business.",
  },
}
