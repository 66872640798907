export default {
  whatWeDo: {
    hero: {
      title: 'O que Fazemos',
    },
    cards: {
      rpa: {
        title: 'RPA',
        text: 'Automatização de tarefas repetitivas e burocráticas',
        linkText: 'saiba mais',
      },
      people: {
        title: 'Pessoas',
        text: 'Recrutamento, Gestão e Desenvolvimento',
        linkText: 'saiba mais',
      },
      process: {
        title: 'Processos',
        text: 'Implantação, Gerenciamento e Otimização',
        linkText: 'saiba mais',
      },
      tech: {
        title: 'Tecnologias',
        text: 'Identificação, Execução e Monitoramento',
        linkText: 'saiba mais',
      },
      hunting: {
        title: 'Tech. <br> Hunting',
        text: 'Contratação Assertiva e Ágil',
        linkText: 'saiba mais',
      },
      sap: {
        title: 'Inteligência SAP',
        linkText: 'saiba mais',
        text: 'Consultoria, Implantação e Suporte AMS',
      },
    },
    conectPeople: {
      title: 'Conectamos Pessoas, Processos e Tecnologias',
      text: 'Nós somos uma empresa especializada em inteligência tecnológica que desenvolve a estratégia ideal para entregar as melhores soluções personalizadas para cada desafio dos nossos clientes.',
      footer: 'Para nós, cada cliente é único!',
    },
    innovationWhell: {
      title: 'Inovação e<br class="mobile" /> Transformação Digital',
      subtitle: 'O que Fazemos',
      eye: {
        title: 'Avaliação de<br> Maturidade Digital',
        description:
          '<span class="font-bold">Fornecemos uma avaliação detalhada</span> da maturidade e eficiência na utilização de tecnologias para guiar organizações em suas iniciativas de Inovação e Transformação Digital.',
      },
      system: {
        title: 'Desenvolvimento de Aplicações Digitais',
        description:
          '<span class="font-bold">Desenvolvemos soluções digitais sob medida,</span> incluindo aplicativos personalizados e a integração de sistemas legados com novas soluções digitais.',
      },
      horse: {
        title: 'Transformação Contínua',
        description:
          '<span class="font-bold">Promovemos a evolução tecnológica </span>por meio de processos de mudança eficazes, incluindo treinamento e suporte contínuo.',
      },
      document: {
        title: 'Consultoria em Estratégia Digital',
        description:
          '<span class="font-bold">Orientamos na definição de metas </span>alinhadas à era digital e no planejamento de estratégias inovadoras para alcançar a Transformação Digital.',
      },
      glass: {
        title: 'Inovação e Pesquisa de Mercado',
        description:
          '<span class="font-bold">Conduzimos pesquisas para<br> identificar tendências</span> de mercado e inovações, aplicando-as para desenvolver ou otimizar produtos e serviços.',
      },
      mental: {
        title: 'Automação de Processos, Integridade e Inteligência',
        description:
          '<span class="font-bold">Somos especialistas em Dados e Inteligência Aplicada</span> e em automação de processos com RPA e IA visando a otimização e eficiência operacional.',
      },
    },
    tabs: {
      people: {
        title: 'Pessoas',
        subtitle:
          'Somos uma empresa feita de pessoas para pessoas. Acreditamos que as relações são etapas importantes no dia a dia e no desenvolvimento das empresas.',
        cards: [
          {
            title: 'Recrutamento e Tech. Hunting:',
            list: ['• Identificação e atração de talentos especializados em tecnologia e Inovação Digital.'],
            textButton: 'saiba mais',
            link: '/hunting',
          },
          {
            title: 'Desenvolvimento e Treinamento:',
            list: [
              '• Programas de capacitação em tecnologias emergentes;',
              '• Metodologias ágeis;',
              '• DevOps;',
              '• UX/UI design;',
              '• Inovação aberta.',
            ],
            link: '/contato',
          },
          {
            title: 'Desenvolvimento de Liderança e Cultura de Inovação:',
            list: [
              '• Coaching e workshops para liderança digital;',
              '• Gestão da mudança;',
              '• Formação de mestres digitais;',
              '• Adaptação cultural para Transformação Digital.',
            ],
            link: '/contato',
          },
          {
            title: 'Formação de Squads e Alocação de Talentos:',
            list: [
              '• Montagem de equipes ágeis multidisciplinares para projetos específicos;',
              '• Alocação de profissionais especializados em Transformação Digital e Inovação.',
            ],
            link: '/contato',
          },
        ],
      },
      process: {
        title: 'Processos',
        subtitle:
          'Desenvolvemos estratégias personalizadas para cada um dos nossos clientes, de acordo com as necessidades específicas de cada empresa. Cada etapa é importante, desde a consultoria até o treinamento e suporte.',
        cards: [
          {
            title: 'Automação e Otimização de Processos:',
            list: [
              '• Consultoria em RPA;',
              '• Lean Management;',
              '• Six Sigma;',
              '• Integração de Inteligência artificial para processos empresariais.',
            ],
            link: '/contato',
          },
          {
            title: 'Frameworks de Desenvolvimento Ágil:',
            list: [
              '• Implementação e treinamento em frameworks ágeis em escala (SAFe, Less, Nexus);',
              '• DevSecOps e gestão ágil de portfólio.',
            ],
            link: '/contato',
          },
          {
            title: 'Estratégias de Mercado e Go-to-Market:',
            list: [
              '• Assessoria para definição e execução de estratégias de mercado;',
              '• Análise de dados;',
              '• Inteligência competitiva.',
            ],
            link: '/contato',
          },
          {
            title: 'Gestão de Inovação e Parcerias Estratégicas:',
            list: [
              '• Criação de processos de ideação, brainstorming e laboratórios de inovação;',
              '• Fomento de colaborações estratégicas.',
            ],
            link: '/contato',
          },
          {
            title: 'Centro de Excelência em Qualidade (CEQ)',
            list: [
              '• Implementação de padrões de excelência em todas as fases do projeto;',
              '• Estabelecimento de critérios rigorosos de aceitação e automação de testes para eficiência;',
              '• Capacitação das equipes técnicas, monitoramento por KPIs e conformidade com normas.',
            ],
            link: '/contato',
          },
        ],
      },
      tech: {
        title: 'Tecnologias',
        subtitle:
          'Impulsionamos negócios por meio de nossos serviços e produtos e estamos sempre em busca das melhores soluções para os nossos clientes. Levamos o que existe de mais inovador para você!',
        cards: [
          {
            image: '/images/services-products/cards/card-sap.webp',
            title: 'Inteligência SAP',
            list: [
              '• Consultoria e implantação SAP;',
              '• SAP ECC;',
              '• SAP S/4HANA;',
              '• Suporte AMS;',
              '• SAP CONCUR;',
              '• PRICEFX;',
              '• OMNIPOS (Omnichannel);',
              '• ANFE.',
            ],
            link: '/o-que-fazemos/inteligencia-sap',
            textButton: 'saiba mais',
          },
          {
            title: 'Aplicações Multiplataforma Sob medida:',
            list: [
              '• Desenvolvimento de Sistemas;',
              '• Engenharia de software abrangendo sistemas cloud;',
              '• Mobile e Web;',
              '• Foco em UX/UI e segurança integrada.',
            ],
            link: '/contato',
          },
          {
            title: 'Desenvolvimento, Implantação e Customização:',
            list: [
              '• De plataformas de mercado (ERPs SAP e Totvs);',
              '• De chatbots inteligentes;',
              '• Em ferramentas Low code/No Code;',
              '• Pega, Innoveo, Salesforce e Totvs.',
            ],
            link: '/contato',
          },
          {
            title: 'Big Data, Analytics e Inteligência Artificial:',
            list: [
              '• Soluções para análise de grandes volumes de dados;',
              '• Mineração de dados;',
              '• Implementação de Sistemas de Recomendação com IA.',
            ],
            link: '/contato',
          },
          {
            title: 'Sustentação de Sistemas:',
            list: [
              '• Continuidade de TI; ',
              '• Monitoramento 24x7; ',
              '• Manutenção Corretiva, Adaptativa e Evolutiva; ',
              '• Suporte Técnico; ',
              '• Gestão de Configuração; ',
              '• Avaliação de Desempenho;',
              '• Evolução Sistêmica.',
            ],
            link: '/contato',
          },
          {
            title: 'Cloud Computing:',
            list: [
              '• Serviços de modernização e integração de legados;',
              '• Arquitetura de sistemas;',
              '• Migração para nuvem;',
              '• Gerenciamento de infraestrutura.',
            ],
            link: '/contato',
          },
          {
            title: 'Monitoramento, Telemetria e DevOps:',
            list: [
              '• Implementação de soluções de monitoramento em tempo real;',
              '• Telemetria avançada;',
              '• Práticas de CI/CD para otimização contínua.',
            ],
            link: '/contato',
          },
          {
            title: 'Plataforma <br>RPA:',
            list: [
              '• Automação de processos burocráticos e aumento da produtividade do time; Integração com multiplas ferramentas;',
              '• Redução de custos;',
              '• Segurança da informação e LGPD e diminuição de falhas e riscos;',
              '• Aquisição como serviço e pagamento mensal pelo uso e adaptável a qualquer porte de empresa.',
            ],
            link: '/o-que-fazemos/rpa',
            textButton: 'saiba mais',
          },
          {
            title: 'Cyber Security (software):',
            list: ['• Pentest;', '• Análise e correção de vulnerabilidade;', '• BlueRed Team;', '• Gestão de Acessos.'],
            link: '/contato',
          },
          {
            title: 'Infraestrutura de TI:',
            list: [
              '• Gestão de Nuvem Pública, Privada e Data Center Convencional;',
              '• Capacity Plan;',
              '• Gestão de Ambiente de TI (servidores, firewall, switches e wireless);',
              '• Migração de Ambientes on premise para Nuvem;',
              '• Monitoramente NOC e SOC;',
              'Suporte e Help Desk.',
            ],
            link: '/contato',
          },
        ],
      },
    },
    ourDifferences: {
      title: 'Nossos Diferenciais',
      items: [
        {
          title: 'Abordagem Completa <br class="desktop">e Personalizada',
          text: 'Soluções adaptadas às necessidades únicas de cada cliente, considerando seus objetivos, cultura e desafios específicos.',
          icon: '/images/services-products/icons/1.webp',
        },
        {
          title: 'Especialização <br class="desktop">Setorial',
          text: 'Expertise em diversos setores, oferecendo soluções que refletem a realidade de cada indústria.',
          icon: '/images/services-products/icons/2.webp',
        },
        {
          title: 'Ecossistema de <br class="desktop">Parceiros Estratégicos',
          text: 'Colaborações com líderes tecnológicos, startups e acadêmicos para potencializar a inovação.',
          icon: '/images/services-products/icons/3.webp',
        },
        {
          title: 'Avaliação de Risco <br class="desktop">e Segurança',
          text: 'Foco na segurança cibernética como pilar do processo de Transformação Digital.',
          icon: '/images/services-products/icons/4.webp',
        },
        {
          title: 'Workshops e <br class="desktop">Eventos Educativos',
          text: 'Oferta de eventos para compartilhamento de conhecimentos e insights relevantes.',
          icon: '/images/services-products/icons/5.webp',
        },
        {
          title: 'Cliente no Centro',
          text: 'Garantindo que as entregas atendam e superem as expectativas do cliente mesmo diante de desafios complexos.',
          icon: '/images/services-products/icons/6.webp',
        },
      ],
    },
    contact: {
      title: 'Fale com a Gente!',
      description:
        'Quer conversar com um de nossos especialistas? Então fale com a gente. Temos uma equipe qualificada para atender as suas necessidades.',
      buttonText: 'Conte com a Gente',
    },
    midia: {
      title: 'SIS na Mídia',
      desc1: 'Nossos especialistas e serviços são destaque em vários canais de comunicação do país.',
      desc2: 'Confira as notícias na íntegra e conheça mais sobre a gente!',
      buttonText: 'Veja tudo',
    },
  },
}
