import hero from './hero'
import howWeAre from './howWeAct'
import contentImageSideAndBottom from './contentImageSideAndBottom'
import howWeDo from './howWeDo'
import clients from './clients'
import midia from './midia'
import manifest from './manifest'

export default {
  ...hero,
  ...howWeAre,
  ...contentImageSideAndBottom,
  ...howWeDo,
  ...clients,
  ...midia,
  ...manifest,
  metatag: {
    title: 'Quem somos - SIS Innov & Tech',
    description:
      'Há mais de 20 anos no mercado, somos uma consultoria estratégica de Inovação e Transformação Digital. Nossa especialidade é impulsionar negócios.',
  },
}
