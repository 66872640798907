export default {
  whatWeDo: {
    hero: {
      title: 'What We Do',
    },
    cards: {
      rpa: {
        title: 'RPA',
        text: 'Automation of repetitive and bureaucratic tasks',
        linkText: 'find out more',
      },
      people: {
        title: 'People',
        text: 'Recruitment, Management and Development',
        linkText: 'find out more',
      },
      process: {
        title: 'Processes',
        text: 'Implantation, Management and Optimization',
        linkText: 'find out more',
      },
      tech: {
        title: 'Technologies',
        text: 'Identification, Execution and Monitoring',
        linkText: 'find out more',
      },
      hunting: {
        title: 'Tech. <br> Hunting',
        text: 'Assertive and Fast Hiring',
        linkText: 'find out more',
      },
      sap: {
        title: 'SAP Intelligence',
        text: 'Consultancy, Implementation and AMS Support',
        linkText: 'find out more',
      },
    },
    conectPeople: {
      title: 'We Connect People, Processes and Technologies',
      text: 'We are a company specialized in technological intelligence who develop the ideal strategy to deliver the best customized solutions to every challenge of our clients.',
      footer: 'For us, every client is unique!',
    },
    innovationWhell: {
      title: 'Innovation and Digital Transformation',
      subtitle: 'What We Do',
      eye: {
        title: 'Evaluation of<br> Digital Maturity',
        description:
          '<span class="font-bold">We provide a detailed evaluation</span> of the maturity and efficiency in the use of technologies to guide organizations in their Innovation and Digital Transformation initiatives.',
      },
      system: {
        title: 'Development of Digital Applications',
        description:
          '<span class="font-bold">We develop made to measure digital solutions,</span> including customized applications and the integration of legacy systems with new digital solutions.',
      },
      horse: {
        title: 'Continuous Transformation',
        description:
          '<span class="font-bold">We promote the technological evolution</span> through effective processes of change, including training and continuous support.  ',
      },
      document: {
        title: 'Consultancy in Digital Strategy',
        description:
          '<span class="font-bold">We guide the definition of targets</span> aligned to the digital era and in the planning of innovative strategies to achieve Digital Transformation.',
      },
      glass: {
        title: 'Innovation and Market Research',
        description:
          '<span class="font-bold">We conduct research to identify market</span> trends and innovations, applying them to develop or optimize products and services.',
      },
      mental: {
        title: 'Automation of Processes, Integrity and Intelligence',
        description:
          '<span class="font-bold">We are specialists in applied Data and Intelligence</span> and in automation of processes with RPA and AI aimed at optimization and operational efficiency.',
      },
    },
    tabs: {
      people: {
        title: 'People',
        subtitle:
          'We are a company made of people for people. We believe that relationships are important stages in day-to-day life and in the development of companies.',
        cards: [
          {
            title: 'Recruitment and <br>Tech. Hunting:',
            list: ['• Identification and attraction of talent specialized in technology and Digital Innovation.'],
            textButton: 'learn more',
            link: '/hunting',
          },
          {
            title: 'Development and Training:',
            list: [
              '• Training programs in emerging technologies;',
              '• Agile methodologies;',
              '• DevOps;',
              '• UX/UI design;',
              '• Open innovation.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Leadership Development and Innovation Culture:',
            list: [
              '• Coaching and workshops on digital leadership;',
              '• Change management;',
              '• Training of digital masters;',
              '• Cultural adaptation for Digital Transformation.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Formation of Squads and Allocation of Talents:',
            list: [
              '• Setting up agile multidisciplinary teams for specific projects;',
              '• Allocation of professionals specialized in Digital Transformation and Innovation.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
        ],
      },
      process: {
        title: 'Processes',
        subtitle:
          'We develop customized strategies for each one of our clients, according to the specific needs of each company. Every stage is important, from the consultancy through to the training and support.',
        cards: [
          {
            title: 'Automation and Optimization of Processes:',
            list: [
              '• Consultancy in RPA;',
              '• Lean Management;',
              '• Six Sigma;',
              '• Integration of Artificial Intelligence for business processes.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Frameworks of Agile Development:',
            list: [
              '• Implementation and training in agile frameworks at scale (SAFe, Less, Nexus);',
              '• DevSecOps and agile portfolio management.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Market Strategies and <br>Go-to-Market:',
            list: [
              '• Consultancy in the definition and execution of market strategies;',
              '• Data analysis;',
              '• Competitive intelligence.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Innovation Management and Strategic Partnerships:',
            list: [
              '• Creation of ideation processes, brainstorming and innovation laboratories;',
              '• Encouraging strategic collaborations.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Center of Excellence <br>in Quality (CEQ)',
            list: [
              '• Implementation of standards of excellence in all phases of the project;',
              '• Establishing rigorous criteria of acceptance and automation of tests for efficiency;',
              '• Training of technical teams, monitoring for KPIs and compliance with standards.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
        ],
      },
      tech: {
        title: 'Technology',
        subtitle:
          'We boost businesses through our services and products and we always seek the best solutions for our clients. We take what there is of the most innovative for you!',
        cards: [
          {
            image: '/images/services-products/cards/card-sap.webp',
            title: 'SAP Intelligence',
            list: [
              '• SAP consultancy and implementation;',
              '• SAP ECC;',
              '• SAP S/4HANA;',
              '• AMS Support;',
              '• SAP CONCUR;',
              '• PRICEFX;',
              '• OMNIPOS (Omnichannel);',
              '• ANFE.',
            ],
            link: '/o-que-fazemos/inteligencia-sap',
            textButton: 'learn more',
          },
          {
            title: 'Made to Measure Multiplatform Applications:',
            list: [
              '• Systems Development;',
              '• Software engineering covering cloud systems;',
              '• Mobile and Web;',
              '• Focus on UX/UI and integrated security.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Development, Implantation and Customization:',
            list: [
              '• Market platforms (ERPs SAP and Totvs);',
              '• Intelligent chatbots;',
              '• In Low code/No Code tools;',
              '• Pega, Innoveo, Salesforce and Totvs.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Big Data, Analytics and Artificial Intelligence:',
            list: [
              '• Solutions for analysis of large data volumes;',
              '• Data mining;',
              '• Implementation of Recommendation Systems with AI.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Sustaining Systems:',
            list: [
              '• IT Continuity;',
              '• Monitoring 24x7;',
              '• Corrective, Adaptive and Evolutive maintenance;',
              '• Technical Support;',
              '• Configuration Management;',
              '• Performance Evaluation;',
              '• Systemic Evolution.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Cloud Computing:',
            list: [
              '• Services of modernization and legacy integration;',
              '• Architecture of systems;',
              '• Migration to the cloud;',
              '• Infrastructure Management.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'Monitoring, Telemetry <br>and DevOps:',
            list: [
              '• Implementation of monitoring solutions in real time;',
              '• Advanced telemetry;',
              '• CI/CD Practices for continuous optimization.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'RPA Platform:',
            list: [
              '• Automation of bureaucratic processes and increasing time productivity; Integration with multiple tools;',
              '• Reduction of costs;',
              '• Information security and LGPD and reducing failures and risks;',
              '• Acquisition as a service and monthly payment for use and adaptable to any size of company.',
            ],
            link: '/o-que-fazemos/rpa',
            textButton: 'learn more',
          },
          {
            title: 'Cyber Security (software):',
            list: [
              '• Pentest;',
              '• Analysis and correction of vulnerability;',
              '• Blue/Red Team;',
              '• Access Management.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
          {
            title: 'IT Infrastructure:',
            list: [
              '• Public, Private Cloud and Conventional Data Center Management;',
              '• Capacity Plan;',
              '• Management of IT environment (servers, firewall, switches and wireless);',
              '• Migration of Environments on premise to the Cloud;',
              '• Monitoring NOC and SOC;',
              '• Support and Help Desk.',
            ],
            textButton: 'talk to us',
            link: '/contato',
          },
        ],
      },
    },
    ourDifferences: {
      title: 'Our Differentials',
      items: [
        {
          title: 'Complete and <br class="desktop">Customized Approach',
          text: 'Solutions adapted to the unique needs of the client, considering their objectives, culture and specific challenges.',
          icon: '/images/services-products/icons/1.webp',
        },
        {
          title: 'Sector <br class="desktop">Specialization',
          text: 'Expertise in various sectors, offering solutions that reflect the reality of each industry.',
          icon: '/images/services-products/icons/2.webp',
        },
        {
          title: 'Ecosystem of Strategic Partnerships',
          text: 'Collaborations with technological leaders, startups and academics to potentialize innovation.',
          icon: '/images/services-products/icons/3.webp',
        },
        {
          title: 'Risk Evaluation<br class="desktop"> and Security',
          text: 'Focus on cyber security as a pillar of the process of Digital Transformation.',
          icon: '/images/services-products/icons/4.webp',
        },
        {
          title: 'Workshops and <br class="desktop">Educational Events',
          text: 'Offer of events for knowledge sharing and relevant insights.',
          icon: '/images/services-products/icons/5.webp',
        },
        {
          title: 'Client at <br class="desktop">the Center',
          text: 'Guaranteeing that the deliveries attend and exceed expectations of the client even when faced with complex challenges.',
          icon: '/images/services-products/icons/6.webp',
        },
      ],
    },
    contact: {
      title: 'Talk to us!',
      description:
        'Do you want to speak to one of our specialists? So talk to us. We have a team qualified to attend to your needs.',
      buttonText: 'Count on Us',
    },
    midia: {
      title: 'SIS in the Media',
      desc1: 'Our specialists and services are highlighted on various communication channels of the country.',
      desc2: 'Check out the all the news and find out more about us!',
      buttonText: 'See all',
    },
  },
}
