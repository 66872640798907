import design from './design'
import devOps from './devOps'
import transformation from './transformation'
import ux from './ux'

export default {
  innovation: {
    design: {
      ...design,
    },

    devOps: {
      ...devOps,
    },

    transformation: {
      ...transformation,
    },

    ux: {
      ...ux,
    },
  },
}
