export default {
  contentImageSideAndBottom: {
    top: {
      title: '¡DE PERSONAS<br> A PERSONAS!',
      content:
        'Sabemos que las personas son nuestro mayor bien, por eso, las cuidamos, las acogemos, las formamos y desarrollamos nuestro equipo y, así, garantizamos entregas alineadas a las expectativas y que generan un valor real para los clientes y para nosotros.',
    },

    bottom: {
      title: 'DÓNDE ACTUAMOS',
      content:
        'Nuestra sede está localizada en Alphaville, pero tenemos especialistas en diversos lugares del país y del mundo. Por eso, estamos aptos para atender a clientes en diferentes localidades y empresas de todos los sectores y segmentos.',
      button: 'Come and join SIS',
    },

    bottomGptw: {
      button: 'Descubre más',
    },
  },
}
