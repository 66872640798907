import messagesPt from '../src/libs/language_switch/pt'
import messagesEn from '../src/libs/language_switch/en'
import messagesEs from '../src/libs/language_switch/es'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'pt',
  globalInjection: true,
  warnHtmlMessage: false,
  messages: {
    pt: {
      message: messagesPt,
    },
    en: {
      message: messagesEn,
    },
    es: {
      message: messagesEs,
    },
  },
}))
