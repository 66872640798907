export default {
  footer: {
    text1: '© 2023 SIS. Todos os direitos reservados.',

    text2: 'Privacidade',

    text3: 'Termos de uso',

    text4: 'Código de conduta',

    text5: 'SERVIÇOS',

    text6: 'Estratégias',

    text7: 'Transformação Digital',

    text8: 'Sustentação',

    text9: 'Desenvolvimento',

    text10: 'Soluções Low Code',

    text11: 'CONTATO',

    text12: 'contato@sis-it.com',

    text13: '+55 11 3711 6530',

    text14: 'Ouvidoria',

    text15: 'Denúncias',

    text16: '© 2021 SIS. Todos os direitos reservados.',

    text17: 'LGPD',

    text18: ['Acompanhe nosso dia a dia', 'em nossas redes sociais.'],

    text19: 'Política de Cookies',
    text20: 'Manifesto ESG',
  },
}
